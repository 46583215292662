import React, {FC, useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import {useMutation} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import {Translate} from '../../components/translations';

import {VERIFY_NOTIFICATION_EMAIL} from '../../graphql/mutations';
import {UserVerifyComponentProps} from '../../types';

const PrivacyPolicy: FC<UserVerifyComponentProps> = ({token}) => {
  const [verifyMutation, {data}] = useMutation(VERIFY_NOTIFICATION_EMAIL);
  const [errorMessage, setError] = useState('');

  useEffect(() => {
    verifyMutation({variables: {verify_token: token}});
  }, []);

  useEffect(() => {
    if (data?.PropertySettingsOps?.verifyNotificationEmail) {
      const {error, message} = data.PropertySettingsOps.verifyNotificationEmail;
      if (error) {
        setError(message);
      } else {
        navigate('/');
      }
    }
  }, [data]);

  return (
    <Layout>
      <div className="content-wrapper">
        <PageHero title="VERIFY_SUBTITLE" />
        <Section>
          <div className="col-lg-12 text-center">
            {errorMessage ? (
              <div className="alert alert-danger">
                <Translate name={errorMessage} />
              </div>
            ) : (
              <FontAwesomeIcon icon={faSpinner} spin />
            )}
          </div>
        </Section>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
